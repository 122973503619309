<template>
  <div class="mt-2 container-fluid">
    <div class="row">

      <div class="col-lg-6" v-if="$store.state.usuarioDB.rol==2 || $store.state.usuarioDB.rol==4">
        <div class="card" >
          <div class="card-header">
            <i class="fa fa-tag"></i> Órdenes de Pago   
          </div>
          <div class="card-body">  
            <div class="row">
              <table class="table table-sm table-bordered table-condensed">
                <tbody>
                  <tr>
                    <td>
                      Parte Diario Órdenes de Pago:
                    </td>  
                    <td class="text-center">
                      <a class="btn btn-success btn-sm" @click="parteDiarioOrdenes()"><i class="fa fa-file-excel-o"></i> Excel</a>
                    </td>               
                  </tr>                 
                   
                </tbody>              
              </table>
            </div>
            <div class="row">      
              <h5>Reportes Históricos Órdenes</h5>        
              <div class="col-lg-6">
                <small>Desde:</small>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="busqueda.fechaInicio"
                  :max="fechaMax"
                />
              </div>
              <div class="col-lg-6">
                <small>Hasta:</small>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="busqueda.fechaFin"
                  :max="fechaMax"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <small>Estado:</small>
                <div class="input-group">
                  <select
                    class="form-select form-select-sm"                        
                    v-model="estado"                      
                  >
                    <option v-for="(item,index) in estados" :key="index" :value="item.id">{{item.valor}}</option>
                  </select>
                  <a class="btn btn-secondary btn-sm" @click="verAyuda()"><i class="fa fa-question-circle"></i></a>
                </div>                
              </div>
              <div class="col-lg-6">
                <small class="text-white">..</small><br>
                <a class="btn btn-success btn-sm" @click="historialOrdenes()"><i class="fa fa-file-excel-o" ></i> Generar Reporte</a>
              </div>
            </div>

          </div>
        </div> 
      </div>

      <div class="col-lg-6" v-if="$store.state.usuarioDB.rol==3 || $store.state.usuarioDB.rol==4">
        <div class="card" >
          <div class="card-header">
            <i class="fa fa-ticket"></i> Facturas   
          </div>
          <div class="card-body">  
            <div class="row">
              <table class="table table-sm table-bordered table-condensed">
                <tbody>
                  <tr>
                    <td>
                      Parte Diario Facturas:
                    </td>  
                    <td class="text-center">
                      <a class="btn btn-success btn-sm" @click="parteDiarioFacturas()"><i class="fa fa-file-excel-o"></i> Excel</a>
                    </td>               
                  </tr>                 
                   
                </tbody>              
              </table>
            </div>
            <div class="row">      
              <h5>Reportes Históricos Facturas</h5>        
              <div class="col-lg-6">
                <small>Desde:</small>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="busquedaSufe.fechaInicio"
                  :max="fechaMax"
                />
              </div>
              <div class="col-lg-6">
                <small>Hasta:</small>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="busquedaSufe.fechaFin"
                  :max="fechaMax"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <small>Estado:</small>
                <div class="input-group">
                  <select
                    class="form-select form-select-sm"                        
                    v-model="estadoSufe"                      
                  >
                    <option v-for="(item,index) in estadosSufe" :key="index" :value="item.id">{{item.valor}}</option>
                  </select>
                  <a class="btn btn-secondary btn-sm" @click="verAyudaInfo()"><i class="fa fa-question-circle"></i></a>
                </div>                
              </div>
              <div class="col-lg-6">
                <small class="text-white">..</small><br>
                <a class="btn btn-success btn-sm" @click="historialFacturas()"><i class="fa fa-file-excel-o"></i> Generar Reporte</a>
              </div>
            </div>

          </div>
        </div> 
      </div>

    </div>

    <b-modal
        class="modal fade"
        :title="tituloModal"
        size="md"
        ref="info"
        hide-footer
        :no-close-on-backdrop="true"
    >
      <div class="container">
        <div class="card-body">
          <div class="row">  
            <p><strong>PENDIENTE: </strong> La orden de pago aún no fue emitida ni factura.</p>
            <p><strong>ANULADO: </strong> El usuario anuló la orden de pago.</p>
            <p><strong>EMITIDO: </strong> Se emitió la orden de Pago a Impuestos Nacionales.</p>
            <p><strong>FACTURA: </strong> Impuestos Nacionales procesó orden de pago.</p>
          </div>  
        </div>
        <hr>
        <div class="row">
          <div class="text-end">            
            <a class="btn btn-secondary" @click="hideModalInfo()"><i class="fa fa-times"></i> Cerrar</a>
          </div>          
        </div>
      </div>
    </b-modal>

    <b-modal
        class="modal fade"
        :title="tituloModal"
        size="md"
        ref="infoFacturas"
        hide-footer
        :no-close-on-backdrop="true"
    >
      <div class="container">
        <div class="card-body">
          <div class="row">  
            <p><strong>FACTURADO: </strong> Impuestos Nacionales emitió la factura correctamente</p>
            <p><strong>OBSERVADO: </strong> Impuestos Nacionales observó la factura (verifique los datos para subsanar)</p>
            <p><strong>ANULADO: </strong> El usuario anuló la factura.</p>
          </div>  
        </div>
        <hr>
        <div class="row">
          <div class="text-end">            
            <a class="btn btn-secondary" @click="hideModalInfofacturas()"><i class="fa fa-times"></i> Cerrar</a>
          </div>          
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Loading from '@/components/Loading';
import moment from 'moment';

export default {
  data(){
    return {
      fechaMax: new moment().format("YYYY-MM-DD"),
      busqueda: {
        fechaInicio:this.fechaActual(),
        fechaFin:this.fechaActual()
      },
      busquedaSufe: {
        fechaInicio:this.fechaActual(),
        fechaFin:this.fechaActual()
      },
      tituloModal:'',
      estado:'P',
      estadoSufe:'PROCESADO',
      estados:[{id:'P','valor':'PENDIENTE'}, {id:'A','valor':'ANULADO'}, {id:'F','valor':'FACTURADO'}, {id:'E','valor':'EMITIDO'}],
      estadosSufe:[{id:'PROCESADO','valor':'FACTURADO'}, {id:'A','valor':'ANULADO'}, {id:'OBSERVADO','valor':'OBSERVADO'}]
    }
  },
  components: {
    Loading    
  },
  
  methods: {

    crearDescarga(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'blob' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },
    async historialOrdenes(){
      let config = { headers: {token: this.token}, responseType: 'blob'};
      this.cargando= true;
      try {
        let resultados = await this.axios.get(`/reporteOrdenes/${this.estado}/${this.busqueda.fechaInicio}/${this.busqueda.fechaFin}`, config);
        await this.crearDescarga(resultados, 'historialOrdenes.xlsx');
        this.cargando = false;
      } catch (error) {
        console.log(error);
        this.cargando = false;
      } 
    },
    async parteDiarioOrdenes(){
      let config = { headers: {token: this.token}, responseType: 'blob'};
      this.cargando= true;
      try {
        let resultados = await this.axios.get(`/reporteOrdenesDiario`, config);
        await this.crearDescarga(resultados, 'parteDiarioOrdenes.xlsx');
        this.cargando = false;
      } catch (error) {
        console.log(error);
        this.cargando = false;
      } 
    },
    async historialFacturas(){
      let config = { headers: {token: this.token}, responseType: 'blob'};
      this.cargando= true;
      try {
        let resultados = await this.axios.get(`/reporteFacturas/${this.estadoSufe}/${this.busquedaSufe.fechaInicio}/${this.busquedaSufe.fechaFin}`, config);
        await this.crearDescarga(resultados, 'historialFacturas.xlsx');
        this.cargando = false;
      } catch (error) {
        console.log(error);
        this.cargando = false;
      } 
    },
    async parteDiarioFacturas(){
      let config = { headers: {token: this.token}, responseType: 'blob'};
      this.cargando= true;
      try {
        let resultados = await this.axios.get(`/reporteFacturasDiario`, config);
        await this.crearDescarga(resultados, 'parteDiarioFacturas.xlsx');
        this.cargando = false;
      } catch (error) {
        console.log(error);
        this.cargando = false;
      } 
    },
    fechaActual(){
      const ff = "YYYY-MM-DD"
      let date = new Date();
      return moment(date).format(ff);
    },
    verAyuda(){
      this.tituloModal='información de Estados';
      this.$refs["info"].show();
    },
    hideModalInfo() {
      this.$refs["info"].hide(); 
    },
    verAyudaInfo(){
      this.tituloModal='información de Estados SUFE';
      this.$refs["infoFacturas"].show();
    },
    hideModalInfofacturas() {
      this.$refs["infoFacturas"].hide(); 
    }

  },
  created(){

  }

}
</script>
